import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route,Routes} from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Blog from './Components/Blog';
import Contact from './Components/Contact';
import Seller from './Components/Seller';
import Retailer from './Components/Retailer';
import Blogdetails from './Components/Blogdetails';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
      <Route path="/" element={<Home />} /> 
      <Route path="/about" element={<About />} /> 
      <Route path="/blog" element={<Blog />} /> 
      <Route path="/contact" element={<Contact />} /> 
      <Route path="/seller" element={<Seller />} /> 
      <Route path="/retailer" element={<Retailer />} /> 
      <Route path="/:id"  exact element={<Blogdetails />} /> 
     
     
      
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
