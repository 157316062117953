import React, {Component, useState, } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import $ from 'jquery'; 


function Contact() {

  
	
	let authToken="SOGjhYKNYtck4teM3O76aUKxHv8m58kd5OD/oDuEoL8U0Ln3o2IySf4aAvFvL9Av3CtbIgShGhcfw2CBB3900abZKy6nPcshKOdge5zqnyBiJ2NJsMFQ+Iti63mrxBduvvxOjlK+Lvykor08Gm+4wQ==";
	const [Name, setName] = useState("");
//const [LastName, setLastName] = useState("");
	const [Email, setEmail] = useState("");
	const [Mobile, setMobile] = useState("");
	const [Comments, setComments] = useState("");
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const [error, setError] = useState('');
	const ContactPara = {
    
		"templateID": "2"
		, "_1": Name
		, "_2": Email
		, "_3": Mobile
		, "_4": Comments
	  }
	function fun_Contact() {
		if (Name.trim() === "") {
		  setError("Please enter name.");
		  document.getElementById('txtFirstName').focus();;
		  return;
		}
	
		if (Email.trim() === "") {
		  setError('Please enter email id.');
		  document.getElementById('txtEmail').focus();;
		  return;
		}
		else if (!emailRegex.test(Email.trim())) {
		  setError('Please enter valid email id.');
		  document.getElementById('txtEmail').focus();;
		  return;
		}
		else if (Mobile.trim() === "") {
		  setError('Please enter mobile no."');
		  document.getElementById('txtmobile').focus();;
		  return;
		}
		else if (Comments.trim() === "") {
		  setError('Please enter your message"');
		  document.getElementById('txtmessage').focus();;
		  return;
		}
	
		fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
		  method: "POST",
		  headers: {
			"Authorization-Token": authToken,
			Accept: "application/json, text/plain",
			"Content-Type": "application/json;charset=UTF-8",
		  },
		  body: JSON.stringify(ContactPara),
		}).then((result) => {
		  result.json().then((resp) => {
			debugger;
			if (resp.hasErrors === false) {
			  alert("Data save successfully.");
			 // window.location.reload(true)
			} else {
			  alert(resp.errors);
			}
		  });
		});
	  }


  return (
    <>
    <div className="innerpage">
        <div><Header /></div>

       <div>
       <div className="appie-page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="appie-page-title-item">
            <h3 className="title">Contact Us</h3>
         
            {/* <div className="thumb">
              <img src="assets/images/about-thumb-5.png" alt="" style={{
    maxWidth: 'initial'}} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>

<section className="contact-section">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="contact--info-area">
          <h3>Get in touch</h3>
          <p>Looking for help? Fill the form.</p>
          <div className="single-info">
            <h5>Headquaters</h5>
            <p>
              <i className="fal fa-home" />
              Shop No 9, Building No 1165, Kucha Natwa, Kucha Mahajani, Chandni Chowk,New Delhi
            </p>
          </div>
          <div className="single-info">
            <h5>Phone</h5>
            <p>
              <i className="fal fa-phone" />
              +91 11 4904 4145
              <br />
              +91 11 6133 6155
            </p>
          </div>
          <div className="single-info">
            <h5>Support</h5>
            <p>
              <i className="fal fa-envelope" />
              info@savyajewelsbusiness.com
            </p>
          </div>
          <div className="ab-social">
            <h5>Follow Us</h5>
            <a className="fac" href="#">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="twi" href="#">
              <i className="fab fa-twitter" />
            </a>
            <a className="you" href="#">
              <i className="fab fa-youtube" />
            </a>
            <a className="lin" href="#">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="contact-form">
          <h4>Let’s Connect</h4>
         
          <form action="#" className="gane-form">
									<div className="row">
									<div class="col-md-6">
										<input type="text" value={Name} className={`form-control ${error && Name.trim() === "" ? 'is-invalid' : ""}`}
                                         onChange={(e) => setName(e.target.value)} id="txtFirstName" placeholder="Name" />
										{error && Name.trim() === "" && <div className="invalid-feedback">Please enter name.</div>}
									</div>

							
									
									
									
									<div className="col-md-6">
										<input type="tel" value={Mobile} className={`form-control ${error && Mobile.trim() === "" ? 'is-invalid' : ""}`}
                                         onChange={(e) => setMobile(e.target.value)} id="txtmobile" placeholder="Mobile" />
										 {error && Mobile.trim() === "" && <div className="invalid-feedback">Please enter mobile no.</div>}
									</div><div className="col-md-12">
										<input type="email" value={Email} className={`form-control ${error && Email.trim() === "" ? 'is-invalid' : ""}`}
                                        onChange={(e) => setEmail(e.target.value)} id="txtEmail" placeholder="Your Email" />
										{error && Email.trim() === "" && <div className="invalid-feedback">Please enter email id.</div>}
									</div>
									<div className="col-md-12">
										<textarea value={Comments} className={`form-control ${error && Comments.trim() === "" ? 'is-invalid' : ""}`}
                                         onChange={(e) => setComments(e.target.value)} rows="3" id="txtmessage" placeholder="Enter your message"></textarea>
										  {error && Comments.trim() === "" && <div className="invalid-feedback">Please enter your message.</div>}
									</div>
                  <div className="col-md-12">
									<button className="btn btn-theme" type="submit" onClick={fun_Contact}>Send Message</button> 
                  </div>
                  </div>
								</form>
        </div>
      </div>
    </div>
  </div>
</section>


       </div>
      
      <div>
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default Contact;
